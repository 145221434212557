<template>
	<div id="html5-container" v-if="video">
		<video id="video-flash" width="100%" height="100%" :poster="preview">
			<img :src="preview" title="Подождите" alt="Превью" />
		</video>
		<v-menu
			v-model="showMenu"
			absolute
			offset-y
			:position-x="menuPos[0]"
			:position-y="menuPos[1]"
			style="max-width: 600px"
		>
			<v-list>
				<v-list-item @click="muteToggle">
					<v-list-item-title>Убрать звук</v-list-item-title>
				</v-list-item>
				<v-list-item @click="openViolation">
					<v-list-item-title>Сообщить модератору</v-list-item-title>
				</v-list-item>
				<v-list-item v-if="allowMark" @click="markAsViewed">
					<v-list-item-title>Пометить как просмотренную</v-list-item-title>
				</v-list-item>
				<v-list-item @click="displayHotkeys">
					<v-list-item-title>Горячие клавиши</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<v-snackbar v-model="showMessage" :timeout="5000" top>
			{{ message }}
			<v-btn text @click="showMessage = false">Закрыть</v-btn>
		</v-snackbar>
		<violation-form :video="video" ref="violation" @onSave="snackbar" @onClose="playerFocus(true)" />
		<component :is="hotkeyDialog" ref="hotkeys"/>
	</div>
</template>

<script>
import { Helper } from "../mixins/Helper";
import $ from "cash-dom";
import "../vendor/krasview/mediaelement-and-player.js";
import "../assets/player.scss";

import ViolationForm from "../components/helper/violation-form.vue";

export default {
	props: { video: Object, section: String, autoplay: Boolean },
	mixins: [Helper],
	components: { ViolationForm },
	data() {
		return {
			player: null,
			volume: 0.8,
			showMenu: false,
			menuPos: [0, 0],
			message: false,
			showMessage: false,
			allowMark: false,
		};
	},
	created() {
		let volume = localStorage.getItem("volume");
		if (volume) this.volume = volume;
		this.allowMark = this.video.allowMark;
		this.$nextTick(this.playerInit);
		this.$eventBus.$on("player-focus", this.playerFocus);
	},
	watch: {
		video: function() {
			this.allowMark = this.video.allowMark;
			this.reload();
		}
	},
	computed: {
		preview() {
			return this.getVideoPhoto(this.video.v_key, this.video.preview, "");
		},
		ratio() {
			if (!this.video.extra.width) return 1.5;
			return (
				Math.round(
					(this.video.extra.height / this.video.extra.width) * 100
				) / 100
			);
		},
		subtitles() {
			let t = this;
			return t.video.extra.sub_info.map((sub, idx) => {
				sub.src = t.getSubPath(sub, t.video);
				sub.default = t.video.subtitle - 1 == idx ? 1 : 0;
				return sub;
			});
		},
		hotkeyDialog() {
			return () => import("../components/helper/hotkeys.vue");
		}
	},
	methods: {
		playerInit() {
			var t = this;
			t.setSizeOnRatio();

			$("#video-flash").mediaelementplayer({
				//poster: t.preview,
				type: "video/mp4",
				videoWidth: $("#video-flash").width(),
				videoHeight: $("#video-flash").height(),
				duration: t.video.duration,
				parent: t,
				tracks: t.subtitles,
				debug: process.env.NODE_ENV != "production",
				initVolume: t.volume,
				success: function(media, node) {
					let player = node.player,
						position = t.video.position ? t.video.position : 0;
					t.player = player;

					if (!t.autoplay) {
						position = t.getPosition(
							t.video.v_id,
							t.video.duration,
							position
						);
						if (position == -1) position = 0;
					}

					if (!position) {
						const marks = t.video.extra.marks;
						for (let mark of marks) {
							if (mark.s == 'b') position = mark.t;
						}
					}

					t.setSrc(0);
					if (t.video.dash) player.setCurrentTime(position);

					if (t.autoplay) player.play();

					var setPos = function() {
						if (position && !t.video.dash) {
							player.setCurrentTime(position);
							position = 0;
						}
						media.removeEventListener(
							"loadedmetadata",
							setPos,
							false
						);
					};
					media.addEventListener("loadedmetadata", setPos, false);

					var lastTime = 0,
						progress_int = t.video.duration > 10 ? 10 : 1,
						countDebug = 0;
					media.addEventListener(
						"timeupdate",
						function() {
							var ct = parseInt(media.currentTime);
							if (media.dash_player && ct != lastTime) {
								var stat = media.dash_player.getStats();
								if (
									stat.estimatedBandwidth <
									stat.streamBandwidth * 0.7
								) {
									if (countDebug == 10) {
										t.$socket.send({
											action: "video_lag",
											video_id: t.video.v_id,
											url: player.src,
											user_agent:
												window.navigator.userAgent,
											streamBandwidth: parseInt(
												stat.streamBandwidth
											),
											estimatedBandwidth: parseInt(
												stat.estimatedBandwidth
											)
										});
									}
									countDebug++;
								}
							}

							if (
								ct != lastTime &&
								ct % progress_int === 0 &&
								ct > 1
							) {
								t.$socket.send({
									action: "video_upd_pos",
									video_id: t.video.v_id,
									time: ct
								});
								lastTime = ct;
								localStorage.setItem("pos" + t.video.v_id, ct);
							}
						},
						false
					);

					media.addEventListener(
						"volumechange",
						function() {
							if (
								!isNaN(media.volume) &&
								media.volume != t.volume
							) {
								t.volume = media.volume;
								localStorage.setItem("volume", media.volume);
							}
						},
						false
					);

					var to_next = function(data) {
						if (data.error && player.isFullScreen) {
							player.exitFullScreen();
						}
						if (data.v_id)
							t.$router.push({
								name: "video",
								params: { id: data.v_id },
								query: { autoplay: 1 }
							});
					};

					var getNext = function(direction, auto) {
						if (t.section != "movie") {
							window.ajax(
								"/api/video-next",
								{
									body: {
										video_id: t.video.v_id,
										channel_id: t.video.channel_id,
										direction: direction,
										auto: auto
									}
								},
								to_next
							);
							return true;
						}
						return false;
					};
					player.container.on("video.next", function(ev, auto) {
						getNext(0, auto ? 1 : 0);
					});
					player.container.on("video.prev", function() {
						getNext(1, 0);
					});

					player.container.on("controlshidden", function() {
						if (player.isFullScreen)
							$("body").css("cursor", "none");
						else if ($("body").css("cursor") == "none")
							$("body").css("cursor", "");
					});

					player.container.on("controlsshown", function() {
						if ($("body").css("cursor") == "none")
							$("body").css("cursor", "");
					});

					player.globalBind(
						// eslint-disable-next-line
						mejs.MediaFeatures.fullScreenEventName,
						function() {
							if (!player.isFullScreen) {
								if (typeof screen.orientation != "undefined")
									screen.orientation.unlock();
								if ($("body").css("cursor") == "none")
									$("body").css("cursor", "");
							} else {
								if (!player.controlsAreVisible)
									$("body").css("cursor", "none");
								if (typeof screen.orientation != "undefined")
									screen.orientation.lock("landscape").catch(function e() {});
							}
						}
					);

					function on_wheel(e) {
						var delta = e.deltaY || e.detail || e.wheelDelta;
						var newVolume = Math.min(
							media.getVolume() + (delta > 0 ? -0.1 : 0.1),
							1
						);
						if (newVolume >= 0 && newVolume <= 1)
							media.setVolume(newVolume);
						e.preventDefault();
					}

					player.container.on("wheel", function(e) {
						if (player.isFullScreen) on_wheel(e);
					});

					$(".mejs-controls").on("wheel", function(e) {
						if (!media.paused) on_wheel(e);
					});
				}
			});
		},
		getPosition(v_id, duration, position) {
			let pos = localStorage.getItem("pos" + v_id);
			if (pos && !position) position = pos;

			if (duration > 150 && position > duration - 30) position = 0;
			else if (duration <= 150 && position > duration - 15) position = 0;

			return position;
		},
		setSizeOnRatio() {
			let size,
				video = $("#html5-container");
			if (video.length) {
				if (size == undefined) size = { w: video.width(), h: 600 };
				let ratio = this.ratio,
					hr = size.w * ratio;

				if (hr && hr > size.h) hr = size.h;
				if (hr && hr < 250) hr = 250;

				if (this.player) this.player.setPlayerResize(size.w, hr);
				video.css("height", hr);
			}
		},
		setSrc(track) {
			let t = this;
			return t.player.setSrc(
				t.video.audio > 1 && !t.video.dash
					? t.video.url1 + "?stream=1"
					: t.video.url1,
				t.video.audio > 1 && t.video.url2
					? t.video.url2 + "?stream=1"
					: t.video.url2,
				track,
				t.videoTitleFilterLite(t.video.v_title)
			);
		},
		videoTitleFilterLite(title) {
			let re = new RegExp(" / .*? - \\s*", "i" );
			return title.replace(re, " - ");
		},
		reload() {
			var t = this,
				newSrc = t.setSrc(t.player.audioTrack);
			t.setSizeOnRatio();
			newSrc.then(
				function() {
					t.player.pause();
					t.player.setCurrentTime(0);
					t.player.setPoster(t.preview);
					t.player.setTracks(t.subtitles);
					t.player.options.duration = t.video.duration;
					t.player.reinitControls();
					if (t.autoplay) t.player.play();
				},
				function(err) {
					alert(err);
				}
			);
		},
		muteToggle() {
			if (this.player.media.muted) {
				this.player.setMuted(false);
			} else {
				this.player.setMuted(true);
			}
		},
		markAsViewed() {
			window.ajax(
				"/api/video/" + this.video.v_id + "/mark",
				{},
				(data, error) => {
					if (!data.error && !error) {
						this.snackbar("Видео отмечено как просмотренное");
						this.allowMark = false;
					}
				}
			);
		},
		snackbar(text) {
			this.message = text;
			this.showMessage = true;
		},
		openViolation() {
			this.player.hasFocus = false;
			this.$refs.violation.openDialog();
		},
		playerFocus(val) {
			this.player.hasFocus = val;
		},
		play() {
			if (this.player.media.paused) this.player.play();
		},
		displayHotkeys() {
			this.$refs.hotkeys.openDialog();
		}
	},
	beforeDestroy: function() {
		if (this.player) this.player.remove();
		this.$eventBus.$off("player-focus");
	}
};
</script>
<style scoped>
#html5-container {
	width: 100%;
	max-width: 1100px;
	height: 489px;
}
</style>